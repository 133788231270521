<template>
  <div class="row justify-center bg-grey-3">
    <q-card flat bordered style="width:300px">
      <q-card-section class="q-gutter-md">
        <q-select
          outlined
          v-model="selKelas"
          label="Kelas"
          :options="kelas"
          @input="
            siswa = [];
            selSiswa = '';
            getListSiswa();
          "
        />
        <q-select
          outlined
          v-model="selSiswa"
          label="Siswa"
          :options="siswa"
          :disable="selKelas == null"
          @input="replaceRoute()"
        />
        <p class="row justify-between">
          <a>Kelas</a>
          <a>{{ selSiswa.kelas }}</a>
        </p>
        <p class="row justify-between">
          <a>Wali Kelas</a>
          <a>{{ selSiswa.walas }}</a>
        </p>
        <p class="row justify-between">
          <a>Ortu</a>
          <a>{{ nullFilter(selSiswa.ortu) }}</a>
        </p>
        <p class="row justify-between">
          <a>Kontak</a>
          <a>{{ nullFilter(selSiswa.kontak) }}</a>
        </p>
        <p class="row justify-between">
          <a>Peringatan</a>
          <a>{{ nullFilter(selSiswa.peringatan) }}</a>
        </p>
      </q-card-section>
      <q-tabs
        v-model="tab"
        no-caps
        indicator-color="orange"
        class="bg-primary text-white"
      >
        <q-tab name="input" label="INPUT" />
        <q-route-tab
          :to="'/hasilrekappembinaan/' + this.selSiswa.value"
          name="riwayat"
          label="RIWAYAT"
          :disable="selSiswa == ''"
        />
      </q-tabs>
      <q-card-section class="q-gutter-sm">
        <a>Uraian Pembinaan :</a>
        <q-input
          v-model="pembinaan.tanggal"
          type="date"
          filled
          :disable="selSiswa == ''"
        />
        <q-input
          v-model="pembinaan.penjelasan"
          label="Penjelasan Kasus"
          filled
          type="textarea"
          @change="allowsave = true"
          :disable="selSiswa == ''"
        />
        <q-input
          v-model="pembinaan.penyelesaian"
          label="Penyelesaian Kasus"
          filled
          type="textarea"
          @change="allowsave = true"
          :disable="selSiswa == ''"
        />
        <q-uploader
          ref="uploader"
          :factory="uploadFn"
          :max-files="1"
          auto-upload
          hide-upload-btn
          label="Upload Gambar"
          flat
          bordered
          style="width: 266px;"
          :disable="selSiswa == ''"
        />
        <q-checkbox
          size="sm"
          label="Beri Peringatan"
          v-model="showSP"
          :disable="selSiswa == ''"
        ></q-checkbox>
        <q-select
          outlined
          dense
          stack-label
          v-if="showSP"
          :disable="selSiswa == ''"
          :options="peringatan"
          label="SP"
          v-model="pembinaan.peringatan"
        ></q-select>
      </q-card-section>
      <q-card-actions class="q-pa-none">
        <q-btn
          filled
          color="negative"
          class="full-width no-border-radius"
          @click="simpan()"
          :disable="!allowsave"
          >simpan</q-btn
        >
      </q-card-actions>
    </q-card>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      uploadURL: process.env.VUE_APP_BASE_URL + "/upload.php",
      user: {},
      allowsave: false,
      tab: "input",
      pembinaan: {
        tanggal: null,
        penjelasan: "",
        penyelesaian: "",
        nama_file: "",
        id_guru: 10,
      },
      dataSiswa: {},
      selSiswa: "",
      siswa: [],
      kelas: [],
      selKelas: null,
      peringatan: ["SP 1", "SP 2", "SP 3"],
      showSP: false,
    };
  },
  async mounted() {
    await this.getUser();
    this.pembinaan.id_guru = this.user.id;
    await this.getKelas();
    if (this.$route.params.id > 0) {
      await this.getSiswaDetail();
      this.selKelas = this.kelas.find(
        (item) => item.value == this.selSiswa.id_rombel
      );
      await this.getListSiswa();
    }

    this.pembinaan.tanggal = moment().format("YYYY-MM-DD");
  },
  methods: {
    ...mapGetters(["getCurrentUser"]),
    async getUser() {
      this.user = await this.getCurrentUser();
    },
    replaceRoute() {
      this.$router.replace({
        path: `/penilaianinputpembinaan/${this.selSiswa.value}`,
      });
    },
    nullFilter(val) {
      if (val && val.length > 0) return val;
      else return "-";
    },
    async getKelas() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/penilaian/pembinaan/getkelasAll/${localStorage.getItem("jenjang")}`
      );
      this.kelas = resp.data;
      this.$q.loading.hide();
    },
    uploadFn(files) {
      let theFile = files[0];
      let newFileName =
        moment().format("YYYY-MM-DD-HH-mm-ss") + "_" + files[0].name;
      this.pembinaan.nama_file = newFileName;
      var formData = new FormData();
      formData.append("file", theFile);
      formData.append("newname", newFileName);
      formData.append("asal", "pembinaan");

      this.$http.post("/upload.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    async getSiswaDetail() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .get("/penilaian/pembinaan/getdetail/" + this.$route.params.id)
        .then((resp) => {
          this.selSiswa = resp.data;
        });
      this.$q.loading.hide();
    },
    async getListSiswa() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });

      await this.$http
        .get(`/penilaian/pembinaan/getsiswa/${this.selKelas.value}`)
        .then((resp) => {
          this.siswa = resp.data;
        });
      this.$q.loading.hide();
    },
    simpan() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      if (!this.showSP) {
        this.pembinaan.peringatan = null;
      }
      this.$http
        .post(
          "/penilaian/pembinaan/tambah/" + this.selSiswa.value,
          this.pembinaan
        )
        .then((resp) => {
          this.pembinaan = {
            penjelasan: "",
            penyelesaian: "",
            tanggal: moment().format("YYYY-MM-DD"),
          };
          this.pembinaan.id_guru = this.user.id;
          this.getSiswaDetail();
          this.$q.notify({
            message: "Pembinaan Berhasil Disimpan!",
            color: "positive",
          });
        });
      this.$q.loading.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/quasar.sass";
</style>
